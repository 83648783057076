.navbar {
  padding: 5px;
  display: flex;
}
.navButton {
  padding: 5px;
}
.header {
  text-align: center;
}
.body {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
	align-items: center;
}
.repoCard {
  text-align: center;
  padding: 10px;
	margin: 0 auto;
}