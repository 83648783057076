.header {
	text-align: center;
}
.description {
	text-align: center;
}
.navbar {
	padding: 5px;
	display: flex;
}
.navButton {
	padding: 5px;
}